import "./css/reset.css"
import "./css/custom-media.css"
import "./css/colors.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/hero.css"
import "./css/details.css"
import "./css/filterview.css"
import "./css/plugins.css"
import "./css/organizationdetail.css"

import "./css/cards.css"
import "./css/cookiecontrol.css"

import "./css/admin.css"
import "./css/variables.css"

import { onReady, qsa } from "./js/utils/dom"
import { initScrolledNav, initToggleNav } from "./js/components/nav"
// import { initNewsletterForm } from "./js/components/newsletterForm"
import { initForms } from "./js/components/form"
import {
  initDirectoryOverview,
  initDirectoryFilteredResults,
} from "./js/components/directoryOverview.js"

onReady(initToggleNav)
onReady(initScrolledNav)
// onReady(initNewsletterForm)
onReady(initForms)
onReady(initDirectoryOverview)
onReady(initDirectoryFilteredResults)

onReady(() => {
  qsa("[data-load]").forEach(async (el) => {
    const response = await fetch(el.dataset.load)
    if (response.ok) {
      el.innerHTML = await response.text()
    }
  })
})

export default function phoneFormatter(field_id) {
  const field = document.getElementById(field_id)

  if (field) {
    Promise.all([
      import("cleave.js/dist/cleave.min"),
      import("cleave.js/dist/addons/cleave-phone.ch"),
    ]).then(([{ default: Cleave }]) => {
      const formatter = new Cleave(field, {
        phone: true,
        phoneRegionCode: "CH",
      })
      formatter.init()
    })
  }
}
