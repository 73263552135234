import { gettext } from "/js/utils/i18n"

export function OrganizationCard({ data, organization, filter }) {
  return (
    <a
      href={organization.url}
      key={organization.id}
      className="card card--organization"
    >
      <h2>{organization.name}</h2>
      <p class="portrait">{organization.portrait}</p>
      <p className="states">{organization.states.join(" ")}</p>
      <div class="organization__activity">
        <h3>{gettext("fields of activity")}</h3>
        <p>
          {organization.fieldsOfActivity.map((slug, i, arr) => (
            <span
              key={slug}
              class={filter.fieldsOfActivity.includes(slug) ? "active" : ""}
            >
              {data.fieldsMap[slug]}
              {arr.length - 1 !== i ? ", " : ""}
            </span>
          ))}
        </p>
      </div>
      <div class="organization__issues">
        <h3>{gettext("core issues")}</h3>
        <p>
          {organization.coreIssues.map((slug, i, arr) => (
            <span
              key={slug}
              class={filter.coreIssues.includes(slug) ? "active" : ""}
            >
              {data.issuesMap[slug]}
              {arr.length - 1 !== i ? ", " : ""}
            </span>
          ))}
        </p>
      </div>
      <span class="centered">
        <button class="btn btn--shadowed">{gettext("Show profile")}</button>
      </span>
    </a>
  )
}
