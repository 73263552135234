import { render } from "preact"
import { useEffect, useState } from "preact/hooks"

import { qs } from "/js/utils/dom"
import { gettext, interpolate } from "/js/utils/i18n"

import { OrganizationCard } from "./organizationCard"
import { DirectoryFilter } from "./filterView"
// import { DirectoryBubble } from "./bubbleView"

const lsKey = "vdzg-filter"

const VIEWS = {
  Filter: "filter",
  Bubble: "bubble",
}

const usePersistedState = (key, initialStateFn) => {
  const [state, setState] = useState(() => _lsGet(key) || initialStateFn())
  useEffect(() => {
    _lsSet(key, state)
  }, [state, key])
  return [state, setState]
}

export function initDirectoryOverview() {
  const el = qs("[data-directory-overview]")
  if (el) {
    let data = JSON.parse(qs("script", el).textContent)
    data = _getDataJSON(el)
    render(<DirectoryOverview layout="standalone" data={data} />, el)
  }
}

export function initDirectoryFilteredResults() {
  const el = qs("[data-directory-filtered-results]")
  if (el) {
    let data = JSON.parse(qs("script", el).textContent)
    data = _getDataJSON(el)
    let initFilter = _lsGet(lsKey)
    render(
      <OrganizationsList
        data={data}
        filter={initFilter}
        organizations={filteredOrganizations(data.organizations, initFilter)}
      />,
      el,
    )
  }
}

function _getDataJSON(el) {
  let data = JSON.parse(qs("script", el).textContent)
  return {
    ...data,
    organizations: data.organizations.map((o) => ({
      ...o,
      _fts: [
        o.name,
        o.portrait,
        o.contactFullName,
        o.contactFunction,
        o.contactPortrait,
        ...o.fieldsOfActivity,
        ...o.coreIssues,
        ...o.networkOrganizations,
      ]
        .join(" ")
        .toLowerCase(),
    })),
    fieldsMap: Object.fromEntries(
      data.fieldsOfActivity.map((field) => [field.slug, field.name]),
    ),
    issuesMap: Object.fromEntries(
      data.coreIssues.map((issue) => [issue.slug, issue.name]),
    ),
    languagesMap: Object.fromEntries(
      data.languages.map((lang) => [lang[0], lang[1]]),
    ),
  }
}

export const emptyFilterState = {
  // Empty lists => Filter is inactive
  // Non-empty lists => Filter has to match (all values in case of sets!)
  fieldsOfActivity: [],
  coreIssues: [],
  networkOrganizations: [],
  foundingYear: [0, 9999],
  languages: [],
  query: "",
  view: VIEWS.Filter,
}

const _arrays = [
  "fieldsOfActivity",
  "coreIssues",
  "networkOrganizations",
  "languages",
]
function filterToQuery(filter) {
  const params = new URLSearchParams()
  for (let key of _arrays) {
    for (let value of filter[key]) {
      params.append(key, value)
    }
  }
  //  params.append("foundingYear", filter.foundingYear.join("-"))
  if (filter.query) {
    params.append("query", filter.query)
  }
  return params.toString()
}

function queryToFilter(query) {
  const params = new URLSearchParams(query)
  const filter = { ...emptyFilterState }
  for (let key of _arrays) {
    filter[key] = params.getAll(key) || []
  }
  filter.query = params.get("query")
  return filter
}

const _lsFallback = {}
function _lsSet(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    _lsFallback[key] = value
  }
}
function _lsGet(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (e) {
    return _lsFallback[key]
  }
}

function isSuperset(set, subset) {
  // TODO maybe do this earlier, when parsing the JSON?
  set = new Set(set)
  subset = new Set(subset)
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false
    }
  }
  return true
}

const filteredOrganizations = (organizations, filter) => {
  return organizations.filter((organization) => {
    let val
    if (
      (val = filter.fieldsOfActivity) &&
      val.length &&
      !isSuperset(organization.fieldsOfActivity, val)
    )
      return false
    if (
      (val = filter.coreIssues) &&
      val.length &&
      !isSuperset(organization.coreIssues, val)
    )
      return false
    if (
      (val = organization.foundingYear) &&
      (val < filter.foundingYear[0] || val > filter.foundingYear[1])
    )
      return false
    if (
      (val = filter.languages) &&
      val.length &&
      !isSuperset(organization.languages, val)
    )
      return false
    // if ((val = filter.language) && !organization.languages.includes(val))
    //   return false
    if ((val = filter.query) && !matchesQuery(organization, val)) return false
    return true
  })
}

const matchesQuery = (organization, query) => {
  const words = query.toLowerCase().split(/\s+/)
  return words.every((word) => organization._fts.includes(word))
}

function DirectoryOverview({ data }) {
  // TODO synchronize filter with URL (use history.replaceState)
  const [filter, setFilter] = usePersistedState(
    lsKey,
    () => _lsGet(lsKey) || emptyFilterState,
  )

  useEffect(() => {
    if (window.location.search) {
      setFilter(queryToFilter(window.location.search))
    }
  }, [setFilter])
  useEffect(() => {
    let filter_to_query = filterToQuery(filter)
    if (filter_to_query) {
      window.history.replaceState(null, "", `?${filter_to_query}`)
    } else {
      window.history.replaceState(null, "", window.location.pathname)
    }
  }, [filter])

  const [isCardVisible, setIsCardVisible] = useState(window.innerWidth > 899)

  /* This resize effect does weird stuff on chrome Browser on IOS? */
  /*   useEffect(() => {
    window.addEventListener("resize", () => {
      setIsCardVisible(window.innerWidth > 899)
    })
  }, []) */

  let view
  // if (filter.view == VIEWS.Filter) {
  view = (
    <DirectoryFilter
      data={data}
      filter={filter}
      setFilter={setFilter}
      setIsCardVisible={setIsCardVisible}
    />
  )
  // } else {
  //   view = <DirectoryBubble />
  // }

  return (
    <div class="container">
      {isCardVisible ? (
        ""
      ) : (
        <div class="mobile-search-button">
          <button
            type="button"
            class="btn btn--shadowed"
            onClick={() => {
              setIsCardVisible(!isCardVisible)
            }}
          >
            {gettext("Advanced Search")}
          </button>
        </div>
      )}

      <FilterResultsHeader data={data} filter={filter} setFilter={setFilter} />

      <div class="grid grid--gx">
        <div class="cell cell--extended-gutter md-5">
          {isCardVisible ? view : null}
        </div>
        <div class="cell md-7">
          <OrganizationsList
            data={data}
            filter={filter}
            organizations={filteredOrganizations(data.organizations, filter)}
            setFilter={setFilter}
          />
        </div>
      </div>
    </div>
  )
}

export function toggleFilter(filter, filterType, slug) {
  let val
  if ((val = filter[filterType]) && val.includes(slug)) {
    return {
      ...filter,
      [filterType]: val.filter((v) => v != slug),
    }
  }
  return {
    ...filter,
    [filterType]: [...val, slug],
  }
}

function sortable(param) {
  return param.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
}

function OrganizationsList({ data, filter, organizations }) {
  return (
    <div class="filter-results__list">
      {organizations
        .sort((a, b) => {
          a = sortable(a.name)
          b = sortable(b.name)
          if (a > b) {
            return 1
          }
          if (a < b) {
            return -1
          }
          return 0
        })
        .map((organization) => (
          <OrganizationCard
            data={data}
            organization={organization}
            filter={filter}
            key={organization.id}
          />
        ))}
    </div>
  )
}

function FilterResultsHeader({ data, filter, setFilter }) {
  let organizations = filteredOrganizations(data.organizations, filter)
  return (
    <div class="grid grid--gx">
      <div class="cell md-5">
        {/* <button
          class="btn btn--clean"
          type="button"
          onClick={() => {
            setFilter({
              ...filter,
              view: VIEWS.Filter,
            })
          }}
        >
          {gettext("Filter view")}
        </button>
        <button
          class="btn btn--clean"
          type="button"
          onClick={() => {
            setFilter({
              ...filter,
              view: VIEWS.Bubble,
            })
          }}
        >
          {gettext("Bubble view")}
        </button> */}
      </div>
      <div class="cell md-7">
        <div class="filter-results__header">
          <h2>
            {interpolate(gettext("%(shown)s of %(all)s organisations"), {
              shown: organizations.length,
              all: data.organizations.length,
            })}
          </h2>
          <button
            class="btn btn--clean"
            type="button"
            onClick={() => {
              setFilter(emptyFilterState)
            }}
          >
            {gettext("reset all filters")}
          </button>
        </div>
      </div>
    </div>
  )
}
