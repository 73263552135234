import { useState } from "preact/hooks"
import { gettext } from "/js/utils/i18n"

import { toggleFilter, emptyFilterState } from "./directoryOverview"

const classnames = (...args) => args.filter(Boolean).join(" ")

export function DirectoryFilter({ data, filter, setFilter, setIsCardVisible }) {
  const [isCollapsedFieldsOfActivity, setIsCollapsedFieldsOfActivity] =
    useState(true)
  const [isCollapsedCoreIssues, setIsCollapsedCoreIssues] = useState(true)
  const [isCollapsedLanguage, setIsCollapsedLanguage] = useState(true)

  return (
    <div class="cell md-4">
      <div class="card filter-card">
        <h2>{gettext("Advanced Search")}</h2>
        <button
          type="button"
          class="btn--close"
          onClick={() => {
            setIsCardVisible()
          }}
        />
        <hr class="filter__horizontal-rule" />
        <div class="filter__heading-container">
          <h2 class="filter__heading">{gettext("Fields of activity")}</h2>
          <FilterCollapseButton
            filterType={isCollapsedFieldsOfActivity}
            setFilterType={setIsCollapsedFieldsOfActivity}
          />
        </div>
        <div
          class={`filter__collapse-container ${
            isCollapsedFieldsOfActivity ? "collapsed" : ""
          }`}
        >
          <ul class="filter__button-list">
            {data.fieldsOfActivity.map((field) => (
              <li key={field.slug}>
                <button
                  class={classnames(
                    "btn btn--filter",
                    filter.fieldsOfActivity.includes(field.slug)
                      ? "active"
                      : "",
                  )}
                  type="button"
                  onClick={() => {
                    setFilter((filter) =>
                      toggleFilter(filter, "fieldsOfActivity", field.slug),
                    )
                  }}
                >
                  <svg class="icon icon-magnifying-glass">
                    <use href="#icon-magnifying-glass-white" />
                  </svg>
                  {field.name}
                  <svg class="icon icon-xmark">
                    <use href="#icon-xmark-white" />
                  </svg>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <FilterWarningMessage setFilterType={setIsCollapsedFieldsOfActivity} />
        <hr class="filter__horizontal-rule" />
        <div class="filter__heading-container">
          <h2 class="filter__heading">{gettext("Core issues")}</h2>
          <FilterCollapseButton
            filterType={isCollapsedCoreIssues}
            setFilterType={setIsCollapsedCoreIssues}
          />
        </div>
        <div
          class={`filter__collapse-container ${
            isCollapsedCoreIssues ? "collapsed" : ""
          }`}
        >
          <ul class="filter__button-list">
            {data.coreIssues.map((issue) => (
              <li key={issue.slug}>
                <button
                  class={classnames(
                    "btn btn--filter",
                    filter.coreIssues.includes(issue.slug) ? "active" : "",
                  )}
                  type="button"
                  onClick={() => {
                    setFilter((filter) =>
                      toggleFilter(filter, "coreIssues", issue.slug),
                    )
                  }}
                >
                  <svg class="icon icon-magnifying-glass">
                    <use href="#icon-magnifying-glass-white" />
                  </svg>
                  {issue.name}
                  <svg class="icon icon-xmark">
                    <use href="#icon-xmark-white" />
                  </svg>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <FilterWarningMessage setFilterType={setIsCollapsedCoreIssues} />
        <hr class="filter__horizontal-rule" />
        <div class="filter__heading-container">
          <h2 class="filter__heading">{gettext("Language")}</h2>
          <FilterCollapseButton
            filterType={isCollapsedLanguage}
            setFilterType={setIsCollapsedLanguage}
          />
        </div>
        <div
          class={`filter__collapse-container ${
            isCollapsedLanguage ? "collapsed" : ""
          }`}
        >
          <ul class="filter__button-list">
            {data.languages.map((lang) => (
              <li key={lang[0]}>
                <button
                  class={classnames(
                    "btn btn--filter",
                    filter.languages.includes(lang[0]) ? "active" : "",
                  )}
                  type="button"
                  onClick={() => {
                    setFilter((filter) =>
                      toggleFilter(filter, "languages", lang[0]),
                    )
                    // setFilter({
                    //   ...filter,
                    //   language: filter.language == code ? [] : code,
                    // })
                  }}
                >
                  <svg class="icon icon-magnifying-glass">
                    <use href="#icon-magnifying-glass-white" />
                  </svg>
                  {lang[1]}
                  <svg class="icon icon-xmark">
                    <use href="#icon-xmark-white" />
                  </svg>
                </button>
              </li>
            ))}{" "}
          </ul>
        </div>
        <FilterWarningMessage setFilterType={setIsCollapsedLanguage} />
        <hr class="filter__horizontal-rule" />
        <label>
          <h2 class="filter__heading">{gettext("Keywords")}</h2>
          <input
            type="text"
            className="filter__input-keyword"
            value={filter.query}
            onInput={(e) => {
              setFilter({
                ...filter,
                query: e.target.value,
              })
            }}
          />
        </label>
        <div class="filter__utilities">
          <button
            class="btn btn--clean"
            type="button"
            onClick={() => {
              setFilter(emptyFilterState)
            }}
          >
            {gettext("reset all filters")}
          </button>
          {/*<button class="btn btn--shadowed">show x results</button>*/}
        </div>
      </div>
    </div>
  )
}

function FilterCollapseButton({ filterType, setFilterType }) {
  return (
    <button
      onClick={() => setFilterType((current) => !current)}
      class="filter__collapse-button"
    >
      {filterType ? (
        <>
          {gettext("view all")}
          <svg class="icon collapse-button__arrow-down">
            <use href="#icon-arrow-down-subtract" />
          </svg>
        </>
      ) : (
        <>
          {gettext("view selected only")}
          <svg class={"icon collapse-button__arrow-up"}>
            <use href="#icon-arrow-down-subtract" />
          </svg>
        </>
      )}
    </button>
  )
}

function FilterWarningMessage({ setFilterType }) {
  return (
    <p
      onClick={() => setFilterType((current) => !current)}
      class="filter__warning-message"
    >
      {gettext("Nothing selected")}
    </p>
  )
}
