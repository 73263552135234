import { qs, qsa } from "/js/utils/dom"
import phoneFormatter from "../../main"

export function initForms() {
  //thank you page is part of the forms
  if (!qs("form")) return

  let step = 0
  let fieldSets = []

  const contact_form = qs("form[data-contact-form]")
  if (contact_form) {
    contact_form.addEventListener("submit", (e) => {
      e.preventDefault()
      fetch(e.target.action, {
        method: e.target.method,
        body: new FormData(e.target),
      })
        .then((res) => res.text())
        .then((data) => {
          const success = qs("[data-email-sent='success']")
          const failed = qs("[data-email-sent='failed']")
          const button = qs("[data-request-button]")
          button.disabled = true
          if (data === "ok") {
            success.style.display = "block"
          } else {
            failed.style.display = "block"
            failed.innerHTML = JSON.parse(data).email
          }
          qs("#id_email").addEventListener("input", () => {
            success.style.display = "none"
            failed.style.display = "none"
            button.disabled = false
          })
        })
    })
    return false
  }

  jumpToError()
  wireUpNavigationButtons()
  createFieldSets()
  watchFieldSet(step)

  function jumpToError() {
    let val
    if (
      (val = qs(".field.error")) &&
      (val = val.closest(".step")) &&
      (val = val.className.match(/step--([0-9]+)/))
    ) {
      step = parseInt(val[1], 10)

      for (let el of qsa(".step")) el.classList.add("hidden")
      qs(`.step--${step}`).classList.remove("hidden")
    }
  }

  //modify fields outlines
  const xmark = ""
  const cbInputs = qsa(".widget--checkboxselectmultiple input")
  const cbLabels = qsa(".widget--checkboxselectmultiple label[for]")
  if (cbInputs) {
    cbInputs.forEach((input) => {
      input.parentElement.insertAdjacentElement("beforebegin", input)
    })
  }
  if (cbLabels) {
    cbLabels.forEach((label) => {
      label.innerHTML += xmark
    })
  }
  const selMultiple = qsa(".widget--selectmultiple select")
  if (selMultiple) {
    selMultiple.forEach((select) => {
      let wrapper = document.createElement("div")
      select.parentElement.appendChild(wrapper)
      wrapper.appendChild(select)
    })
  }

  //Navigation Buttons
  function wireUpNavigationButtons() {
    const btnResetForm = qs(".btn--reset-form")
    const btnsContiune = qsa(".btn--continue")
    const btnsBack = qsa(".btn--back")
    if (btnResetForm) {
      btnResetForm.addEventListener("click", () => {
        document.forms[0].reset()
        window.scrollTo(0, 0)
      })
    }
    if (btnsContiune) {
      btnsContiune.forEach((btn) => {
        btn.addEventListener("click", () => {
          qs(`.step--${step}`).classList.add("hidden")
          step++
          qs(`.step--${step}`).classList.remove("hidden")
          watchFieldSet(step)
          window.scrollTo(0, 0)
        })
      })
    }
    if (btnsBack) {
      btnsBack.forEach((btn) => {
        btn.addEventListener("click", () => {
          qs(`.step--${step}`).classList.add("hidden")
          step--
          qs(`.step--${step}`).classList.remove("hidden")
          watchFieldSet(step)
          window.scrollTo(0, 0)
        })
      })
    }
  }

  function createFieldSets() {
    let sections = qsa(".step")
    sections.forEach((section, i) => {
      fieldSets[i] = {
        watched: false,
        fields: qsa(
          "input[required],textarea[required],select[required],input[type]",
          section,
        ),
      }
    })
  }

  function watchFieldSet(n) {
    let contBtn = qs(`.step--${n} .btn--continue`)
    if (!fieldSets[n].watched) {
      fieldSets[n].fields.forEach((field) => {
        field.addEventListener("input", () => {
          field.setCustomValidity("")
          checkFieldSet(contBtn)
        })
        field.addEventListener("blur", () => {
          setInvalidColor(field)
        })
      })
      fieldSets[n].watched = true
    }
    checkFieldSet(contBtn)
  }

  function checkFieldSet(contBtn) {
    let isValid = true
    fieldSets[step].fields.forEach((field) => {
      isValid &&= field.checkValidity()
    })
    contBtn.disabled = !isValid
  }

  function setInvalidColor(field) {
    field.style = field.checkValidity()
      ? "border-color:none"
      : "border-color:red"
  }

  qsa("textarea[data-auto-resize='true']").forEach((element) => {
    function autoResize(el) {
      if (el.scrollHeight > 38) {
        el.style.height = `${el.scrollHeight}px`
      }
    }

    autoResize(element)
    element.addEventListener("input", () => autoResize(element))
  })

  phoneFormatter("id_phone")

  const languages = ["en", "de", "fr", "it"]

  function checkTextareaHasContent(field_name) {
    let has_content = true
    let default_language = document.querySelector(
      `div[id='id_default_language_${field_name}']`,
    )
    languages.forEach((language) => {
      const portrait = document.querySelector(
        `textarea[name="${field_name}_${language}"]`,
      )
      if (portrait.value === "") {
        has_content = false
      }
    })
    if (has_content) {
      default_language.parentNode.parentNode.style.display = "none"
    } else {
      default_language.parentNode.parentNode.style.display = "block"
    }
  }

  function updateLanguage(field_name) {
    const selected_language = document.querySelector(
      `input[name="${field_name}_language"]:checked`,
    ).value

    const radio_buttons = qsa(`input[name="${field_name}_language"]`)
    radio_buttons.forEach((radio) => {
      radio.checked
        ? radio.parentElement.classList.add("selected")
        : radio.parentElement.classList.remove("selected")
    })
    languages.forEach((language) => {
      let label = document.querySelector(
        `label[for="id_${field_name}_${language}"]`,
      )
      let textarea = document.querySelector(
        `textarea[name="${field_name}_${language}"]`,
      )

      label.style = "display:none"
      if (language === selected_language) {
        textarea.parentNode.parentNode.style = "display:block"
        textarea.classList.add("selected")
      } else {
        textarea.parentNode.parentNode.style = "display:none"
        textarea.classList.remove("selected")
      }
      textarea.addEventListener("keyup", () => {
        checkTextareaHasContent(field_name)
      })
    })
  }

  const default_language_rd_btns = document.querySelectorAll(
    `input[type=radio][name="default_language_portrait"]`,
  )
  const default_language_cq_rd_btns = document.querySelectorAll(
    `input[type=radio][name="default_language_contact_quote"]`,
  )
  const radio_grp = [default_language_rd_btns, default_language_cq_rd_btns]

  const select_radio_btns = (btn_group) => {
    btn_group.forEach((radio) => {
      radio.checked && radio.parentElement.classList.add("selected")
      radio.addEventListener("change", () => {
        btn_group.forEach((group_radio) => {
          group_radio.checked
            ? group_radio.parentElement.classList.add("selected")
            : group_radio.parentElement.classList.remove("selected")
        })
      })
    })
  }

  radio_grp.forEach((group) => {
    select_radio_btns(group)
  })

  function addListener(field_name) {
    const languages = document.querySelectorAll(
      `input[type=radio][name="${field_name}_language"]`,
    )

    languages.forEach((radio) => {
      radio.addEventListener("change", () => {
        updateLanguage(field_name)
      })
    })
    updateLanguage(field_name)
  }

  addListener("portrait")
  addListener("contact_quote")
}
